<script>
import Layout from '@layouts/main'

export default {
  name: 'Reports',
  page: {
    title: 'Relatórios',
  },
  components: {
    Layout,
  },
}
</script>

<template>
  <Layout>
    <div class="animated fadeIn">
      <b-row class="pb-4">
        <b-col cols="12">
          <h2 class="card-title m-0">
            Relatórios
          </h2>
          <div class="small text-muted">
            Última atualização 5min atrás
          </div>
        </b-col>
      </b-row>
      <b-card>
        s
      </b-card>
    </div>
  </Layout>
</template>
